/* Sets the CSS for the entire header section
    rem is used for padding and margins 
    vh is relative to viewport height 
    hidden means overflow of content is hidden. Other options like visible (content renders OUTSIDE the box) and scrollbar (adds a scrollbar to see the rest of the content)
*/
header {
    padding-bottom: 6rem;
    height: 50vh;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}

video {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 50%;
    overflow: hidden;
}

/* dot signifies className */
.header__container {
    padding-top: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.header__name {
    width: 16rem;
}

/* ================================ NAME ANIMATION ======================== */
.header__name > .letter {
    display: inline-block;
    /* transition: transform 800ms ease; */
    transition: var(--transition);
}

.header__name:hover > .letter {
    /* background: linear-gradient(60deg, #ff0000, #ffff00, #00ff22, #00fff7, #0033ff, #9500ff, #ff00ee, #ff006f, #ff0000); */
    background: linear-gradient(90deg, var(--color-light), #fed401, #fed401, var(--color-bg), var(--color-bg), var(--color-light));
    background-size: 800%;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradient 10s linear infinite;
    transition-duration: 500ms; 
}
@keyframes gradient {
    0%{
        background-position: 0%;
    }

    100%{
        background-position: 400%;;
    }
}

.header__name:hover > .letter:nth-child(1) {
    transform: translate(-40%, -20%) rotate(-10deg);
}

.header__name:hover > .letter:nth-child(2) {
    transform: translate(-10%, -40%) rotate(5deg);
}

.header__name:hover > .letter:nth-child(3) {
    transform: translate(-30%, -8%) rotate(-10deg);
}

.header__name:hover > .letter:nth-child(4) {
    transform: translate(-20%, -20%) rotate(-15deg);
}

.header__name:hover > .letter:nth-child(5) {
    transform: translate(-10%, -10%) rotate(8deg);
}

.header__name:hover > .letter:nth-child(6) {
    transform: translate(20%, -34%) rotate(8deg);
}

.header__name:hover > .letter:nth-child(7) {
    transform: translate(20%, -20%) rotate(8deg);
}

.header__name:hover > .letter:nth-child(8) {
    transform: translate(30%, -30%) rotate(-12deg);
}

.header__titles {
    margin-top: 3rem;
}
.header__title {
    color: var(--color-light);
    line-height: 0;
    margin: 0;
}
/* =================== CTA =======================*/

.header__cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ============= MEDIA QUERIES (MEDIUM) ===================*/
@media screen and (max-width:1024px) {  
    header {
        height: auto;
    }
}

/* ============= MEDIA QUERIES (SMALL) ===================*/
@media screen and (max-width:600px) {
    header {
        height: auto;
    }
}
