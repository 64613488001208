.socials {
    opacity: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem; /* Sets gap between the icons */
    position: fixed; /* Fixes it in place on the page */
    right: 95%;
    bottom: 0%;
    animation: fadeIn 4s ease;
    animation-delay: 2s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
    
}

.socials::after {
    content: '';
    width: 1px;
    height: 10rem;
    background: var(--color-light-transparent);
}

/* ============= MEDIA QUERIES (MEDIUM) ===================*/
@media screen and (max-width:1024px) {  
    .socials {
        opacity: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 4rem; /* Sets gap between the icons */
        position: fixed; /* Fixes it in place on the page */
        justify-content: center;
        right: auto;
        bottom: 10%;
        border-radius: 50rem;
    }

    .socials::after {
        content: '';
        width: 10rem;
        height: 1px;
        background: var(--color-light-transparent);
    }
    
    .socials::before {
        display: flex;
        content: '';
        width: 10rem;
        height: 1px;
        background: var(--color-light-transparent);
    }
}

